import {
  AnalyticsEventDetail,
  InternalLocation,
  InternalHistory,
  ObjectMapper
} from "./types";
import { history, location } from "./state";
import { DEPENDENCY_ORDER, FIELDS } from "./config";
import mocks from "./mocks.json";

const inDev: boolean = window.location.host.includes("localhost");
const historyFields: Array<string> = ["length", "scrollRestoration", "state"];

const paramsToMap = () =>
  // @ts-ignore
  [...new URLSearchParams(window.location.search)].reduce(
    (acc, [k, v]) => ({ ...acc, [k]: v }),
    {}
  );

export const dependencyAppender: ObjectMapper = {
  location: (): InternalLocation =>
    Object.assign(
      {
        paramMap: paramsToMap?.()
      },
      window.location,
      inDev ? mocks?.location : {}
    ),
  history: (): InternalHistory =>
    Object.assign(
      {},
      ...historyFields?.map(k => ({
        [k]: (window?.history as { [key: string]: any })[k]
      })),
      {
        ...history
      }
    ),
  local: (detail: AnalyticsEventDetail): Object => detail?.data?.local,
  prevHistory: (): Object => location?.previousLocation
};

export const sortDeps = (a: string, b: string) => {
  const ia = DEPENDENCY_ORDER.indexOf(a);
  const ib = DEPENDENCY_ORDER.indexOf(b);
  return ia === -1 ? 1 : ib === -1 ? -1 : ia - ib;
};

export const buildDepArray = (detail: AnalyticsEventDetail): Array<string> =>
  Array?.from(
    new Set(
      // TODO replace [].concat(...) with .flat() when Node is upgraded to 11.x
      ([] as string[]).concat(
        ...detail?.spec?.fields?.map(f => FIELDS[f].dependencies)
      )
    )
  )
    ?.slice()
    ?.sort(sortDeps);

export const appendDependencies = (detail: AnalyticsEventDetail) => {
  return (dep: string = ""): Object => ({
    [dep]: dependencyAppender?.[dep]?.(detail)
  });
};

export const appendData = (
  detail: AnalyticsEventDetail
): AnalyticsEventDetail => {
  const depArray: Array<string> = buildDepArray(detail);
  return {
    ...detail,
    data: Object.assign(
      { local: detail?.data?.local },
      ...depArray?.map(appendDependencies(detail))
    )
  };
};
