import { StateHistory, StateLocation } from "./types";

export const history: StateHistory = {
  previous: []
};

export const location: StateLocation = {
  previousLocation: []
};

export const addHistory = (item: string, action: string): StateHistory => {
  const list = {
    href: item,
    action
  };
  if (item && item !== history.previous.slice(-1)[0]?.href) {
    history.previous.push(list);
  }
  return history;
};

export const addLocation = prevLocation => {
  if (prevLocation) {
    location.previousLocation?.push(prevLocation);
  }
  return location;
};
